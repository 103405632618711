<template>
  <div class="page-container">
    <!-- header -->
    <el-card>
      <el-row class="header-search-bar">
        <el-col :span="6">
          <el-input
            placeholder="请输入key"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getParamsList('search')"
            @keyup.enter.native="getParamsList('search')"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getParamsList('search')"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
          <el-button type="primary" size="mini" @click="createParams('create')"
            >添加参数</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <el-table :data="paramsList" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="50"> </el-table-column>
        <el-table-column prop="dictTitle" label="参数名" width="150">
        </el-table-column>
        <el-table-column prop="dictDesc" label="描述" width="150">
        </el-table-column>
        <el-table-column prop="dictKey" label="参数key" width="150">
        </el-table-column>
        <el-table-column prop="dictValue" label="参数value" width="150">
        </el-table-column>
        <el-table-column prop="dictSort" label="排序"> </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template v-slot="scoped">
            <el-button
              type="warning"
              size="mini"
              @click="createParams('edit', scoped.row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteUserHandle(scoped.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <add-params :addData="addData" @successHandle="successHandle"></add-params>
  </div>
</template>

<script>
import { getParamsList, deleteParams } from '@/api/api'
import addParams from './dialog/addParams.vue'

export default {
  components: {
    addParams
  },
  data () {
    return {
      addData: {
        id: '',
        title: '',
        isOpen: false,
        type: ''
      },
      queryInfo: {
        currentPage: 1,
        pageSize: 10,
        keyword: ''
      },
      paramsType: [
        {
          title: '系统参数',
          type: 1,
          tag: 'success'
        },
        {
          title: '系统字典',
          type: 2,
          tag: 'danger'
        }
      ],
      paramsList: [],
      total: 0
    }
  },
  created () {
    this.getParamsList()
  },
  methods: {
    // 获取用户列表
    getParamsList (type) {
      if (type === 'search') {
        this.queryInfo.currentPage = 1
      }
      getParamsList(this.queryInfo).then((res) => {
        this.paramsList = res.data.list
        this.total = res.data.total
      })
    },
    // 打开编辑参数
    createParams (type, id) {
      this.addData.isOpen = true
      this.addData.type = type
      if (type === 'create') {
        this.addData.id = ''
        this.addData.title = '设置参数'
      } else {
        this.addData.id = id
        this.addData.title = '编辑参数'
      }
    },
    // 打开分配角色
    openSetRole (id) {
      this.setData = {
        isOpen: true,
        id
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getParamsList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.currentPage = page
      this.getParamsList()
    },
    // 删除用户
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteParams(id).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getParamsList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getParamsList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
